import * as React from 'react';


const Loader = (message) => {

    return (
        <div className="loading">Loading&#8230;</div>
    );
};

export default Loader;