import * as React from "react";
import {
    Show, useRedirect, useNotify, SimpleShowLayout, TextField, DateField, ImageField, BooleanField,
    TopToolbar, DeleteWithConfirmButton, EditButton
} from 'react-admin';
import BackButton from "../backButton";


const HallFrameInfoShow = (props) => {

    const notify = useNotify();
    const redirect = useRedirect();

    const CustomShowActions = ({ basePath, data, resource }) => (
        <TopToolbar>
            <BackButton color='primary' style={{ marginLeft: '5rem' }} />
            <EditButton basePath={basePath} record={data} />
            {data ? <DeleteWithConfirmButton undoable={false} onSuccess={onSuccess} onFailure={onFailure} basePath={basePath} record={data} resource={resource} /> : ''}
        </TopToolbar>
    )

    const onSuccess = () => {
        notify(`Info deleted successfully`, `success`)
        redirect('/hall-frame-info')
    }

    const onFailure = (error) => {
        console.log(error)
        notify(`Info can not be delete`, `warning`)
        redirect('/hall-frame-info')
    }

    return (
        <Show {...props} actions={<CustomShowActions />}>
            <SimpleShowLayout>
                <TextField source="id" sortable={false} />
                <TextField source="id" sortable={false} />
                <TextField source="url" sortable={false} />
                <TextField source="button_text.en" sortable={false} label="Button Text For English" />
                <TextField source="button_text.tu" sortable={false} label="Button Text For Turkish" />
                <TextField source="button_url" sortable={false} label="Button Url" />
                <BooleanField source="isActive" sortable={false} />
                <ImageField source="banner.en" sortable={false} />
                <ImageField source="banner.tu" sortable={false} />
                <ImageField source="mobile.en" sortable={false} />
                <ImageField source="mobile.tu" sortable={false} />
                <DateField source="createdAt" sortable={false} />
            </SimpleShowLayout>
        </Show>
    )
}

export default HallFrameInfoShow;