import Web3 from "web3";


let web3 = null;


const getWeb3 = async () => {
  // Check if browser is running Metamask
  if (window.ethereum) {
    web3 = new Web3(window.ethereum);
  } else if (window.web3) {
    web3 = new Web3(window.web3.currentProvider);
  } else {
    console.log('User is not downloaded in to MetaMask')
  }
};

if (!web3) {
  getWeb3();
}

export {
  web3,
  getWeb3,
}