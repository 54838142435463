import Compressor from 'compressorjs';
import toStream from 'it-to-stream';
import FileType from 'file-type';

import ipfs from "../config/ipfs";


export async function compressImage(image) {
  return new Promise((resolve, reject) => {
    try {
      new Compressor(image, {
        quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          resolve(compressedResult);
          // compressedResult has the compressed file.
          // Use the compressed file to upload the images to your server.
        },
      });
    } catch {
      reject(undefined);
    }
  });
}

export async function getFileType(url) {
  let ipfsHash = url.substring(url.lastIndexOf('/') + 1) // substract ipfs hash
  const ext = await getFile(ipfsHash);
  return ext.substring(0, ext.lastIndexOf('/'))
}

export async function getFile(cid) {
  // const type = await FileType.fromStream(toStream(ipfs.cat(cid, {
  //   length: 100 // or however many bytes you need
  // })))
  const type = await FileType.fromStream(toStream(ipfs.cat(cid, {})))
  return type.mime
}